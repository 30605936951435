<template>
<client-only>
  <ion-content class="whole-page" v-if="userIsStaff">
    <div class="inner-content">
      <!-- <div class="sidebar left-sidebar">
      </div> -->

      <div class="main-content">
        <MainList
          v-if="!currentView"
          @reviewUserModLog="enterReviewUserModLogView"
          @reviewReport="enterReviewReportView"
        />

        <ReviewUserModLogPartial
          v-if="currentView === VIEWS.USER_MOD_LOG"
          :userModLog="currentObject"
          @close="onCloseModal"
        />

        <ReviewReportPartial
          v-if="currentView === VIEWS.REPORT"
          :report="currentObject"
          @close="onCloseModal"
        />
      </div>

      <!-- <div class="sidebar right-sidebar">
      </div> -->
    </div>
  </ion-content>
  <div v-else></div>
</client-only>
</template>

<script lang="ts" setup>
import MainList from './components/MainList.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { adminStore } from '@/shared/pinia-store/admin';
import { formatDateTime } from '@/shared/utils/dateTime';
import ReviewUserModLogPartial from './components/ReviewUserModLogPartial.vue';
import ReviewReportPartial from './components/ReviewReportPartial.vue';
const { toggleDarkness } = mainStore();

const VIEWS = {
  DEFAULT: '',
  REPORT: 'report',
  USER_MOD_LOG: 'userModLog',  
}
const currentView = ref(VIEWS.DEFAULT);
const currentObject = ref(null as any);
const timeInterval = ref(null as any);
const revertedDarkMode = ref(false);

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});

definePageMeta({
  middleware: 'admin'
})

const currentTime = ref('');

onMounted(() => {
  if (!userIsStaff.value) {
    const router = useRouter();
    router.push('/');
    return;
  };
  const { initLoad } = adminStore();
  initLoad();
  currentTime.value = formatDateTime(new Date());
  timeInterval.value = setInterval(() => {
    currentTime.value = formatDateTime(new Date());
  }, 6000);
  const { dark } = mainStore();
  if (dark.value) {
    revertedDarkMode.value = true;
    toggleDarkness();
  }
  window.addEventListener('beforeunload', beforeExit, false)
});

const beforeExit = () => {
  if (timeInterval.value) clearInterval(timeInterval.value);
  if (revertedDarkMode.value) toggleDarkness();
};

const userIsStaff = computed(() => {
  const { user } = authStore();
  return user.value?.is_staff;
});

const onCloseModal = () => {
  currentView.value = VIEWS.DEFAULT;
  currentObject.value = null;
};

const enterReviewUserModLogView = (userModLogId: any) => {
  if (!userModLogId) return;
  currentObject.value = userModLogId;
  currentView.value = VIEWS.USER_MOD_LOG;
};
const enterReviewReportView = (reportId: any) => {
  if (!reportId) return;
  currentObject.value = reportId;
  currentView.value = VIEWS.REPORT;
};
</script>

<style lang="sass" scoped>
ion-page
  overflow-y: scroll !important
  height: 100% !important
.whole-page
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 20px
  min-height: 100vh !important
  height: 100vh !important
.inner-content
  display: flex
  margin: 0 auto
  text-align: left
  .sidebar
    flex: 1
    max-width: 150px
    padding: 20px
    min-height: 100vh
  .main-content
    flex: 5
    padding: 20px
    background: #eee
    min-height: 100vh
  h1.title
    font-size: 1.5rem
    margin-bottom: 1rem
    font-weight: bold
</style>
